html {
  min-height: 100%;
  height: 100%;
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
}

body {
  background: #f5f7fb;
  overflow: auto !important;
}

.overlay-div {
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 200;
  height: 100%;
  width: 100%;
  padding: 10% 10% 15% 10%;
  /**border: 75px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.8);**/
  background-color: rgba(255, 255, 255, 0.85);
}

.overflow-auto {
  overflow: auto;
}

.height-inherit {
  height: inherit;
}

@-webkit-keyframes cf3FadeInOut {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes cf3FadeInOut {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.logo-animation {
  padding: 20%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  -webkit-animation-name: cf3FadeInOut;
          animation-name: cf3FadeInOut;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-duration: 1.5s;
          animation-duration: 1.5s;
}

.floating-add {
  position: absolute;
  right: 50px;
  margin-top: 360px;
  z-index: 50;
  border-radius: 50px;
  width: 50px;
  height: 50px;
}

.title-icon {
  margin-right: 0.75rem;
}

.no-padding {
  padding: 0 !important;
}

.table-ag {
  height: 450px;
  width: 100%;
}

.s-alert-wrapper {
  color: white;
  z-index: 1600 !important;
}

.s-alert-box {
  z-index: 1600 !important;
}

.s-alert-message {
  vertical-align: middle;
  font-size: 1.25rem;
  padding-left: 0.5rem;
}

.alert-text-white {
  color: white
}

.s-alert-icon {
  display: block;
  float: left;
  margin-right: 1rem;
  vertical-align: middle;
}

.spinCenter {
  display: block;
  margin: auto;
}

.div-legend {
  display: table;
}

.legend {
  padding-left: 20px;
  display: table-cell;
  vertical-align: middle;
}

.cell-disp {
  background: rgba(0, 255, 0, 0.1);
  border: 1px solid black;
}

.cell-no-disp {
  background: rgba(255, 251, 0, 0.230);
  /* background: rgba(255,0,0,0.1); */
  border: 1px solid black;
}

.cell-azul {
  background: rgba(183, 228, 255, 0.23);
  /* background: rgba(255,0,0,0.1); */
  border: 1px solid black;
}

/*
.item-disp {
  background-color: rgb(230,255,230);
  border: 1px solid black;
}
*/

.ag-theme-balham .text-no-disp {
  color: red;
}

.ag-theme-balham .text-warn {
  color: orange;
}

.ag-theme-balham .text-outdated {
  color: graytext;
}

.ag-sort-order {
  display: none;
}

.ag-icon-asc {
  display: none !important;
}

.ag-icon-desc {
  display: none !important;
}

.no-click {
  pointer-events: none;
}

.cursor-pointer {
  cursor: pointer;
}

button.close>span {
  display: none !important;
}

button.close {
  color: #FFFFFF
}

.height-scroll {
  min-height: 50vh;
  max-height: 80vh;
  overflow-y: auto;
}

.switch-scroll {
  min-height: 20vh;
  max-height: 30vh;
  overflow-y: auto;
}

.col {
  flex-basis: auto !important;
}

.floating-add {
  position: absolute;
  right: 50px;
  bottom: 50px;
  z-index: 50;
  /*border-radius: 50px;
  width: 70px;
  height: 70px;*/
}

/* fix for react-tabler tooltip*/
.tooltip {
  opacity: 1 !important;
  display: block !important;
  position: absolute !important;
  top: 0 !important;
  right: 50px !important;
  left: auto !important;

}


/*SM*/
@media (min-width: 576px) {
  .modal-dialog {
    margin: 1rem 1rem !important;
  }

  .modal-lg {
    /*margin: 2rem 1rem !important ;  */
    max-width: 100% !important;
  }
}

/*MD*/
@media (min-width: 768px) {
  .modal-dialog {
    margin: 1rem auto !important;
  }

  .modal-lg {
    max-width: 720px !important;
  }
}

/*LG*/
@media (min-width: 992px) {
  .modal-lg {
    max-width: 980px !important;
  }

}

/* ---------------------------/ Scrollbar /--------------------------------------------*/
body *::-webkit-scrollbar {
  width: 12px;
  height: 6px;
  -webkit-transition: 0.3s background;
  transition: 0.3s background;
}

/* ---------------------------------------------------------------------------------*/

/*-----------/ Botón plomo de guardar fila /----------------------------*/
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.padding-three {
  padding: 3px !important
}

/*--------------------------/ Buscador para 'dynamic_filters' /-----------------------*/
div.css-10nd86i {
  width: 301px;
}
